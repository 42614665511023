import * as React from "react"

import styled from "styled-components"

import { Heading, Text, Box } from "grommet"
import StyledImage from "../styled_image"

export const CalculationResults = ({ selectedPercentage, totalPrice, totalCo2 }) => {
  const Bold = styled(Text)`
    font-weight: bold;
    color: #f15a29;
  `

  const KrohneHeading = styled(Heading)`
    font-weight: bold;
    color: #005da8;
  `

  return (
    <>
      <KrohneHeading level="3">Results</KrohneHeading>
      {selectedPercentage === undefined ? (
        <>
          <p>
            To get started
            <ul>
              <li>
                Select some fuel types and enter the yearly consumption for each
                fuel type
              </li>
              <li>
                Click on button to see how much money you can save by reducing
                the fuel consumption by selected percentage
              </li>
            </ul>
          </p>
        </>
      ) : (
        <Box gap="small" align="center" pad={{ top: "none", bottom: "medium", right: "small", left: "small" }}>
          <Text size="16px" textAlign="center">
            Assuming savings of <Bold>{selectedPercentage.percentage}</Bold>{" "}
            your savings would be
            <br />
            <Bold>{formatValue(totalPrice * selectedPercentage?.factor)}US $ yearly.</Bold>
          </Text>
          <Text size="16px" textAlign="center">
            That equates to reducing <Bold>{formatValue((totalCo2 * selectedPercentage?.factor))} MT CO<sub>2</sub></Bold> emissions in the atmosphere each year.
          </Text>
          <StyledImage src={selectedPercentage?.image} width="45%" />
          <Box pad="medium"
            background={{
              image: "url(/brushStrokeOrange.png)",
              size: "100% 100%",
              position: "center",
            }}>
            <Text size="16px" textAlign="center" color="white" weight="bold">
              To put things into perspective.
              <br />
              It takes <Bold style={{ color: "#A9F67D" }}>{formatValue((totalCo2 * selectedPercentage?.factor * 50))}</Bold> trees one year to absorb that amount of CO<sub>2</sub>!
            </Text>
          </Box>
          <hr style={{ width: "100%" }} />
          <Text size="14px" textAlign="center">
            Savings up to <Bold>10%</Bold> have been reported.
          </Text>
          <Text size="12px" textAlign="center">
            By using precise and dependable data to inform enhancements in
            efficiency, such as speed and operations, even a slight decrease in
            consumption can result in a quick return on investment.
          </Text>
        </Box>
      )}
    </>
  )
}

function formatValue(value) {
  return typeof value === "number"
    ? value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    : ""
}
