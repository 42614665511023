import React, { useCallback } from "react"

import { Selectors, CalculationResults, FuelTypeTable } from "./calculator/"
import { KrohneHeading } from "../styles/styles"

import { Box, Grid, Button, ResponsiveContext, Text } from "grommet"
import { CircleInformation } from "grommet-icons"

const Calculator = () => {
  const CO2_FACTOR = 3.17;
  const size = React.useContext(ResponsiveContext);
  const options = [
    { percentage: "5%", factor: 0.05, image: "/savemoneyandnature_frame1.png" },
    { percentage: "7%", factor: 0.07, image: "/savemoneyandnature_frame2.png" },
    { percentage: "10%", factor: 0.1, image: "/savemoneyandnature_frame3.png" },
  ];

  const [selectedPercentage, setSelectedPercentage] = React.useState();
  const [selectedFuelTypes, setSelectedFuelTypes] = React.useState([]);

  const totalPrice =  selectedFuelTypes.reduce((price, value) => price + value.mass * value.currentPrice, 0);
  const totalCo2 = selectedFuelTypes.reduce((co2, value) => co2 + value.mass * CO2_FACTOR, 0);

  const addFuelType = useCallback(fuelType => {
    const existing = selectedFuelTypes.find(f => f.grade === fuelType.grade && f.port === fuelType.port);
    if (!existing) {
      setSelectedFuelTypes(e => [...e, { ...fuelType, mass: 0 }]);
    }
  },
    [selectedFuelTypes]
  )

  const removeFuelType = useCallback(
    fuelType => {
      setSelectedFuelTypes(e => e.filter(f => f !== fuelType));
    },
    []
  );

  const updateSelectedFueltype = useCallback(
    (index, value, column) => {
      setSelectedFuelTypes(e => {
        e[index][column] = value;
        return [...e];
      });
    },
    []
  );

  return (
    <Grid
      columns={size !== "small" ? ["2/3", "1/3"] : ["100%"]}
      rows={size !== "small" ? ["100%", "auto"] : ["auto", "auto"]}
      areas={[
        {
          name: "calculator",
          start: [0, 0],
          end: [0, 0],
        },
        {
          name: "results",
          start: size !== "small" ? [1, 0] : [0, 1],
          end: size !== "small" ? [1, 0] : [0, 1],
        },
      ]}
    >
      <Box
        gridArea="calculator"
        background="#FAFAFA"
        pad={{ left: "medium", right: "medium", bottom: "medium" }}
        round={{ corner: size !== "small" ? "left" : "top" }}
      >
        <Box direction="row">
          <KrohneHeading level="3">EcoMATE™ Savings Calculator</KrohneHeading>
          <Text
            textAlign="center"
            margin={{ left: "auto", right: 0 }}
            alignSelf="center"
            tip={{
              dropProps: { align: { right: "left", top: "bottom" } },
              content: (
                <Text
                  style={{
                    maxWidth: "300px",
                    background: "#EDEDED",
                  }}
                >
                  To get started, select a port to get the fuel types available
                  at that port and the current prices. Then select the yearly
                  consumption of each fuel type selected. This calculator does
                  not take into account variations in the oil price.
                </Text>
              ),
            }}
          >
            <CircleInformation color="#005da8" size="medium" />
          </Text>
        </Box>
        <Grid
          columns={["100%"]}
          rows={["auto", "auto", "auto"]}
          areas={[
            {
              name: "inputs",
              start: [0, 0],
              end: [0, 0],
            },
            {
              name: "fuelTypesUsed",
              start: [0, 1],
              end: [0, 1],
            },
          ]}
        >
          <Box gridArea="inputs">
            <Selectors addFuelType={addFuelType} />
          </Box>
          <Box gridArea="fuelTypesUsed" overflow={{ horizontal: "auto" }}>
            <FuelTypeTable
              selectedFuelTypes={selectedFuelTypes}
              updateSelectedFueltype={updateSelectedFueltype}
              removeFuelType={removeFuelType}
            />
          </Box>
          <Box
            gap="small"
            style={{
              visibility:
                selectedFuelTypes.length !== 0 ? "visible" : "collapse",
            }}
            direction={size !== "small" ? "row" : "column"}
            margin={{
              left: "auto",
              right: "auto",
              top: "medium",
            }}
          >
            {selectedFuelTypes &&
              options.map(percentage => {
                return (
                  <Button
                    hoverIndicator="light-1"
                    active={
                      selectedPercentage?.percentage === percentage.percentage
                    }
                    label={percentage.percentage}
                    onClick={() => setSelectedPercentage(percentage)}
                  />
                )
              })}
          </Box>
        </Grid>
      </Box>
      <Box
        gridArea="results"
        background="#D8E5EF"
        pad={{ left: "medium", right: "medium" }}
        round={{ corner: size !== "small" ? "right" : "bottom" }}
      >
        <CalculationResults
          selectedPercentage={selectedPercentage}
          totalPrice={totalPrice}
          totalCo2={totalCo2}
        />
      </Box>
    </Grid>
  )
}

export default Calculator;
