import React, { useMemo, useState, useEffect } from "react"

import { Select } from "grommet"

const getBunkerPrices = async () => {
  const data = await fetch("/bunker.json");
  return await data.json();
}

export const Selectors = ({ addFuelType }) => {

  const [shippingInfo, setShippingInfo] = useState({ports: [], bunkerPrices: []});
  const [selectedPort, setSelectedPort] = useState();  

  useEffect(() => {
    const func = async () => {
      const response = await getBunkerPrices();
      const shippingInfo = {
        bunkerPrices: response?.prices.sort((a, b) => a.port > b.port ? 1 : a.port < b.port ? -1 : 0),
        ports: [...new Set(response?.prices.map(p => p.port))]
      };
      setShippingInfo(shippingInfo);
    };
    func();
  }, []);

  const fuelTypes = useMemo(() => {
    if (selectedPort) {
      const availableGradesInPort = shippingInfo.bunkerPrices.filter(
        price => price.port === selectedPort
      );
      return availableGradesInPort;
    }
    return [];
  }, [selectedPort, shippingInfo]);

  return (
    <>
      <Select
        placeholder="Choose port(s)"
        options={shippingInfo.ports}
        onChange={event => setSelectedPort(event.value)}
        margin={{ bottom: "medium" }}
      />
      <Select
        placeholder="Select fuel type(s)"
        options={fuelTypes}
        disabled={!selectedPort}
        onChange={event => addFuelType(event.value)}
        labelKey="grade"
        margin={{ bottom: "medium" }}
      />
    </>
  )
}
