import * as React from "react"

import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Text,
  TextInput,
  Button,
  ResponsiveContext,
} from "grommet"

import { Close } from "grommet-icons"

export const FuelTypeTable = ({
  selectedFuelTypes,
  updateSelectedFueltype,
  removeFuelType,
}) => {
  const size = React.useContext(ResponsiveContext)
  const columns = [
    {
      label: "",
      type: "button",
    },
    {
      label: "Grade",
      property: "grade",
      type: "text",
    },
    {
      label: "Port",
      property: "port",
      type: "text",
      visibility: size !== "small" ? "visible" : "hidden",
    },
    {
      label: "Consumption [MT]",
      property: "mass",
      type: "input",
    },
    {
      label: "Current price [US $]",
      property: "currentPrice",
      type: "input",
    },
  ]

  return (
    <Table>
      {selectedFuelTypes.length > 0 && (
        <TableHeader>
          <TableRow>
            {columns
              .filter(column => column.visibility !== "hidden")
              .map(c => (
                <TableCell
                  pad={{ left: "small" }}
                  border={{ style: "hidden" }}
                  key={c.property}
                  scope="col"
                >
                  <Text>{c.label}</Text>
                </TableCell>
              ))}
          </TableRow>
        </TableHeader>
      )}
      <TableBody>
        {selectedFuelTypes.map((selectedFuelType, fuelTypeIndex) => (
          <TableRow key={selectedFuelType.grade.concat(selectedFuelType.port)}>
            {columns
              .filter(column => column.visibility !== "hidden")
              .map((column, index) => (
                <TableCell
                  pad={{ top: "small", left: index !== 0 ? "small" : "0px" }}
                  key={column.label}
                >
                  {column.type === "input" ? (
                    <TextInput
                      type="number"
                      defaultValue={selectedFuelType[column.property]}
                      onChange={value =>
                        updateSelectedFueltype(
                          fuelTypeIndex,
                          value.target.value,
                          column.property
                        )
                      }
                    />
                  ) : column.type === "text" ? (
                    <Text>{selectedFuelType[column.property]}</Text>
                  ) : column.type === "button" ? (
                    <Button
                      onClick={() => removeFuelType(fuelTypeIndex)}
                      icon={<Close size="small" />}
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
              ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
