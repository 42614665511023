import * as React from "react"

import { Box, ResponsiveContext, Heading, Paragraph, } from "grommet"
import { Succeed } from "../components/succeed"
import { KrohneHeading } from "../styles/styles"

import Layout from "../components/layout"
import Container from "../components/container"
import Calculator from "../components/calculator"
import Seo from "../components/seo"
import Button from "../components/linkbutton"

const SavingsCalculatorPage = () => {
  const styledImage = {
    objectFit: "contain",
    width: "100%",
    objectPosition: "top center",
  };

  return (
    <Layout>
      <Seo title="Savings Calculator" />
      <img src="/coins.png" alt="Some coins" style={styledImage} />
      <Container>
        <Box direction="row-responsive" margin={{ top: "large" }}>
          <div>
            <KrohneHeading>
              Even a small reduction of consumption leads to reduced costs
            </KrohneHeading>
            <p>
              Regulations are now used as incentives to encourage sustainability
              and low emissions. This opens the door to reduced costs through
              internal efficiency measures. There are numerous ways to improve
              energy efficiency and reduce carbon footprints, but how much
              potential fuel savings can the EcoMATE™ system unlock? Take a look
              at our calculator below!
            </p>
          </div>
          <div
            style={{
              padding: "50px 50px 75px 100px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "middle",
              height: "100%",
            }}
          >
            <img height="192px" width="192px" alt="An oil tank" src="/oil-tank.svg" />
          </div>
        </Box>
        <Container margin={{ top: "large", bottom: "large" }}>
          <Calculator />
        </Container>
      </Container>
      <Succeed>
        <Heading level="3" style={{ fontWeight: "bold" }}>
            Fast return on investment
        </Heading>
        <Paragraph>
            The amount of potential savings will vary depending on the
            vessel and the extent of fuel-saving measures already
            implemented. Benchmarking your ship will give crucial
            information for fleet and vessel analysis after hull cleaning,
            between crews, or prior to optimising actions. An instantaneous
            overview of current fuel usage will also give the operator
            information to adjust operations and maintain maximum
            efficiency.
        </Paragraph>
        <Button style={{ width: "240px" }} to="/contact_us">
            Get in touch with us today
        </Button>
      </Succeed>
    </Layout>
  )
}

export default SavingsCalculatorPage;
